
import * as bootstrap from 'bootstrap';
import { defineComponent } from 'vue';
import CodeField from '@/components/forms/CodeField.vue';
import { Form } from 'vee-validate';
export default defineComponent({
  name: 'link-mobile-modal',
  emits: ['verifyMobile'],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
    },
  },
  data: () => ({
    modal: {} as HTMLElement,
    formData: {} as any,
    form: {} as unknown as typeof Form,
    accountCodes: [] as any,
    formSubmitted: false,
  }),
  mounted() {
    // setup the modal reference and event listener
    this.form = this.$refs.verificationCodeForm as typeof Form;
    this.modal = document.getElementById(
      'link_mobile_form_modal'
    ) as HTMLElement;

    if (this.modal) {
      this.modal.addEventListener('shown.bs.modal', (e) => {
        this.accountCodes = [];
        this.formSubmitted = true;
      });
    }
  },
  components: {
    Form,
    CodeField,
  },
  computed: {
    formSchema() {
      return {};
    },
  },
  methods: {
    submitForm() {
      const params = {
        code: this.accountCodes.join(''),
      };

      this.form.resetForm();
      this.accountCodes = [];
      this.formSubmitted = true;
      this.$emit('verifyMobile', params);
    },
    handleCodeInput(index, value) {
      this.accountCodes[index] = value;
    },
    resetForm() {
      this.form.resetForm();

      // Reset the form values
      this.accountCodes = [];

      (
        bootstrap.Modal.getInstance(
          this.modal as HTMLElement
        ) as bootstrap.Modal
      ).hide();
    },
  },
});
